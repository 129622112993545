/* eslint-disable import/no-anonymous-default-export */
import { navigationItemType, showForDeviceType, showForUserType, showForProduct, showHasNotProduct } from '../../hooks';
import { alertType } from '../../components/Alert/alertType';


export default [
    {
        label: "חיפוש מילה",
        path: "/",
        show_for_users: [showForUserType.has_permissions],
        show_for_product:[showForProduct.RavMilim],
        type: navigationItemType.link,
        pages: ["search", "search_results"],
    },    
    {
        label: "חיפוש מילה",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    },  
    {
        label: "חיפוש מילה",
        show_for_users: [showForUserType.has_permissions],
        show_has_not_product:[showHasNotProduct.RavMilim],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.temp_alert,
        },
    },  
    {
        label: "תשבצון",
        path: "/crossword",
        show_for_users: [showForUserType.has_permissions],
        show_for_product:[showForProduct.RavMilim],
        type: navigationItemType.link,
        pages: ["crossword"],
    },    
    {
        label: "תשבצון",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    },  
    {
        label: "תשבצון",
        show_for_users: [showForUserType.has_permissions],
        show_has_not_product:[showHasNotProduct.RavMilim],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.temp_alert,
        },
    },  
    {
        label: "נקדן טקסט",
        path: "/nakdan",
        show_for_users: [showForUserType.has_permissions],
        show_for_product:[showForProduct.Nakdan],
        type: navigationItemType.link,
    },    
    {
        label: "נקדן טקסט",
        show_for_users: [showForUserType.has_not_permissions],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.sign_up,
        },
    }, 
    {
        label: "נקדן טקסט",
        show_for_users: [showForUserType.has_permissions],
        show_has_not_product:[showHasNotProduct.Nakdan],
        type: navigationItemType.alert,
        alert_data: {
            type: alertType.temp_alert,
        },
    }, 
    // {
    //     label: "תמלול",
    //     path: "/transcription",
    //     type: navigationItemType.link,
    // },   
    {
        label: "על המילון",
        path: "/about",
        type: navigationItemType.link,
        pages: ["about"],
    },    
    {
        label: "אזור אישי",
        show_for_users: [showForUserType.privet, showForUserType.edu, showForUserType.external],
        show_for_devises: [showForDeviceType.mobile],
        type: navigationItemType.link,
        path: "/user",
        pages: ["user", "personal_information", "change_password"],
    },  
    {
        label: "אזור אישי",
        show_for_users: [showForUserType.privet, showForUserType.edu, showForUserType.external],
        show_for_devises: [showForDeviceType.pc],
        pages: ["user", "personal_information", "change_password", "contact_us"],
        path: "/user",
        type: navigationItemType.dropdown,
        items: [
            {
                label: "פרטים אישיים",
                type: navigationItemType.link,
                show_for_users: [showForUserType.privet, showForUserType.external],
                path: "/personal_information",
                pages: ["personal_information"],
            },
            {
                label: "שינוי סיסמה",
                type: navigationItemType.link,
                show_for_users: [showForUserType.privet, showForUserType.external],
                path: "/change_password",
                pages: ["change_password"],
            },
            {
                label: "כתבו לנו",
                type: navigationItemType.link,
                path: "/contact_us",
                pages: ["contact_us"],
            },
            {
                label: "התנתקות",
                type: navigationItemType.alert,
                alert_data: {
                    type: alertType.sign_out,
                },
            }
        ]
    },    
    {
        label: "כתבו לנו",
        show_for_users: [showForUserType.guest, showForUserType.org],
        type: navigationItemType.link,
        path: "/contact_us",
        pages: ["contact_us"],
    },    
];