import React, { useContext, useMemo } from 'react';
import Href from '../Href';
import Icon from '../Icon';
import NavigationItem from '../NavigationItem';
import { DeviceContext, LanguageContext } from '../../contexts';
import { useLabels, useFilterNavigationItem } from '../../hooks';
import { footer as footerConfig } from "../../config";
import styles from './Footer.module.scss';

const Footer = ({ className = '', ...props }) => {
    const { isMobileView } = useContext(DeviceContext);
    const { language } = useContext(LanguageContext);
    const { getLabel, getLocalUrlByLanguage } = useLabels();
    const { filterNavigationItem } = useFilterNavigationItem();

    const links = useMemo(() => {
        return <div className={styles.links}>{
            filterNavigationItem(footerConfig[language] || []).reduce((resultArray, link, index) => { 
                const chunkIndex = Math.floor(index/4);
                if(!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [];
                }
                resultArray[chunkIndex].push(link);
                return resultArray
            }, []).map((links, chunkIndex) => {
                return <div key={`footer-links-chunk--${chunkIndex}`}>{links.map((link, link_index) => {
                    return <NavigationItem 
                        key={`footer_link--${chunkIndex}_${link_index}`} 
                        className={styles.link} 
                        {...link}
                    />;
                })}</div>;
            })
        }</div>;
    }, [filterNavigationItem, language]); 

    const homePageUrl = useMemo(() => {
        return getLocalUrlByLanguage('/');
    }, [getLocalUrlByLanguage]);

    const socials = useMemo(() => {
        return <div className={styles.socials}>{[
            "social_facebook",
            //"social_instagram",
            "social_phone",
        ].map((social) => {
            const social_link = getLabel(`footer_${social}_url`);
            const social_text = getLabel(`footer_${social}_text`);
            return social_link && <Href key={social} className={styles.social} to={social_link} target='_blank'><Icon className={styles.social_icon} icon={social} title={social_text} /></Href>
        })}</div>;
    }, [getLabel]);

    const credit = useMemo(() => {
        return <div className={styles.credit}>
            <Href to='//www.melingo.com' target='_blank'>powered By www.melingo.com</Href>
            <p>{getLabel('melingo_phone')}</p>
            <p>{getLabel('melingo_address')}</p>        
        </div>;
    }, [getLabel]);

    if (isMobileView) {
        return (
            <footer className={`${styles.footer} ${className}`} {...props}>
                <div>
                    <Href to={homePageUrl}>
                        <Icon className={styles.logo} icon="logo_medium"/>
                    </Href>
                </div>
                <div>{links}</div>
                <div>{credit}</div>
            </footer>
        );
    }

    return (
        <footer className={`${styles.footer} ${className}`} {...props}>
            <div className={styles.footer_section}>
                <Href to={homePageUrl}>
                    <Icon className={`${styles.logo} ${styles[`logo_${language}`]}`} icon={`logo_footer_${language}`}/>
                </Href>
                {credit}
            </div>
            <div className={styles.footer_section}>{links}</div>
            <div className={styles.footer_section}>{socials}</div>
        </footer>
    );
};

export default Footer;