import { env } from '../../../src/config'

class WebSocketService {
    constructor() {
        this.ws = null;
        this.handlers = {};
        this.connectionAttempts = 0;
        this.maxRetries = 5;
        this.isConnected = false;
        this.shouldSendMessages = false;
        this.connect(true); // true indicates initial connection
    }

    connect(isInitial = false) {
        if (this.connectionAttempts >= this.maxRetries) {
            if (!isInitial && this.handlers.onError) {
                this.handlers.onError({ message: 'Failed to connect after multiple attempts' });
            }
            return;
        }
    
        this.ws = new WebSocket(`${env.REACT_APP_TRANSCRIPTION_API_URL}`);
        
        this.ws.onopen = () => {
            console.log('WebSocket connected');
            this.connectionAttempts = 0;
            this.isConnected = true;
            this.shouldSendMessages = true;
            if (this.handlers.onConnectionChange) {
                this.handlers.onConnectionChange(true);
            }
        };

        this.ws.onclose = () => {
            console.log('WebSocket disconnected, trying to reconnect...');
            this.isConnected = false;
            this.shouldSendMessages = false;
            if (this.handlers.onConnectionChange) {
                this.handlers.onConnectionChange(false);
            }
            this.connectionAttempts++;
            setTimeout(() => this.connect(), 1000);
        };

        this.ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            if (!isInitial && this.handlers.onError) {
                this.handlers.onError({ message: 'WebSocket connection error' });
            }
        };
    }

    init(handlers) {
        this.handlers = handlers;
        this.ws.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log('Received message:', data);
                
                switch (data.type) {
                    case 'transcription':
                        this.handlers.onTranscription && this.handlers.onTranscription(data);
                        break;
                    case 'translation':
                        this.handlers.onTranslation && this.handlers.onTranslation(data);
                        break;
                    case 'error':
                        this.handlers.onError && this.handlers.onError(data);
                        break;
                }
            } catch (error) {
                console.error('Error processing message:', error);
            }
        };
    }

    sendAudioData(data) {
        if (this.ws.readyState === WebSocket.OPEN && this.shouldSendMessages) {
            this.ws.send(data);
        } else {
            console.warn('WebSocket is not ready, message not sent');
        }
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
        }
    }
}

export default WebSocketService;
