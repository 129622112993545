class AudioService {
    constructor(onAudioData) {
        this.audioContext = null;
        this.mediaStream = null;
        this.audioProcessor = null;
        this.onAudioData = onAudioData;
        this.isPaused = false;
    }

    async startRecording(onReady) {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
                channelCount: 1,
                sampleRate: 16000,  // Increase sample rate for better quality
                sampleSize: 16,
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: false,
            },
        });

        this.audioContext = new AudioContext({
            sampleRate: 16000,  
            channelCount: 1,          
            sampleSize: 16 ,
            latencyHint: 'interactive'           
        });
        this.mediaStream = this.audioContext.createMediaStreamSource(stream);
        this.audioProcessor = this.audioContext.createScriptProcessor(1024, 1, 1); // Reduced buffer size
        
        this.audioProcessor.onaudioprocess = (event) => {
            if (!this.isPaused) {
                const pcmData = event.inputBuffer.getChannelData(0);
                const rawData = this.float32ToInt16(pcmData);
                this.onAudioData(rawData);
            }
        };

        this.mediaStream.connect(this.audioProcessor);
        this.audioProcessor.connect(this.audioContext.destination);

        if (onReady) {
            onReady(); // Notify that recording has started
        }
    }

    stopRecording() {
        if (this.audioProcessor) {
            this.audioProcessor.disconnect();
        }
        if (this.mediaStream) {
            this.mediaStream.disconnect();
            this.mediaStream.mediaStream.getTracks().forEach(track => track.stop());
        }
    }

    pauseProcessing() {
        this.isPaused = true;
    }

    resumeProcessing() {
        this.isPaused = false;
    }

    float32ToInt16(buffer) {
        const l = buffer.length;
        const result = new Int16Array(l);
        for (let i = 0; i < l; i++) {
            result[i] = Math.max(-1, Math.min(1, buffer[i])) * 0x7fff;
        }
        return result;
    }
}

export default AudioService;