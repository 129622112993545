import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import Button, { buttonType } from '../../components/Button';
import OuterLayer from '../../containers/OuterLayer';
import { useLabels } from '../../hooks';
import { PageContext, OutersContainerContext, DeviceContext, UserContext } from '../../contexts';
import styles from './Nakdan.module.scss';
import Icon from '../../components/Icon';
import Alert from '../../components/Alert';
import { alertType } from '../../components/Alert/alertType';
import Loader from '../../components/Loader';
import WordVocalization from './wordVocalization';
import { useNakdanText } from '../../api/useNakdanText';
import { useNakdanRestoreKtivMale } from '../../api/useNakdanRestoreKtivMale';
import { useParams, useNavigate } from 'react-router-dom';


const pageKey = "nakdan";

const Nakdan = () => {

    const { setPageKey } = useContext(PageContext);
    const { isMobileView } = useContext(DeviceContext);
    const { hasPermissions } = useContext(UserContext)
    const {
        appLayoutTitleContainer,
        appLayoutSubTitleContainer
    } = useContext(OutersContainerContext);
    const { getLabel, getLocalUrlByLanguage } = useLabels();
    const alertRef = useRef();
    const alertWvRef = useRef();
    const containerRef = useRef();
    const userInputRef = useRef();
    const [userInput, setuserInput] = useState("");
    const [dataText, setdataText] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [fontSize, setFontSize] = useState(1.2);
    const [isLocked, setIsLocked] = useState(false);
    const [selectedWord, setSelectedWord] = useState("");
    const [wordPosition, setWordPosition] = useState(null);
    const [textRemove, setTextRemove] = useState(false);
    const { loader: loadNakdanText, error: errorNakdanText, data: dataNakdanText } = useNakdanText(userInput);
    const { loader: loaduseNakdanRestoreKtivMale, error: erroruseNakdanRestoreKtivMale, data: dataNakdanRestoreKtivMale } = useNakdanRestoreKtivMale(dataText);
    const navigate = useNavigate();
    const [touchStartTime, setTouchStartTime] = useState(0);
    const [touchStartPos, setTouchStartPos] = useState({ x: 0, y: 0 });
    const [isTouchMoved, setIsTouchMoved] = useState(false);
    const [lastTapTime, setLastTapTime] = useState(0);

    const LONG_PRESS_DURATION = 500;
    const DOUBLE_TAP_THRESHOLD = 300;

    useEffect(() => {
        setPageKey(pageKey);
    }, [setPageKey]);

    useEffect(() => {
        if (!hasPermissions) {
            navigate(getLocalUrlByLanguage(`/`));
        }
    }, [hasPermissions, getLocalUrlByLanguage, navigate]);

    const increaseFontSize = useCallback(() => {
        setFontSize(prevFontSize => prevFontSize + 0.2);
    }, []);

    const decreaseFontSize = useCallback(() => {
        setFontSize(prevFontSize => Math.max(prevFontSize - 0.2, 0.7));
    }, []);

    const clearText = useCallback(() => {
        setdataText(null);
        setTextRemove(false)
        userInputRef.current.value = null
        userInputRef.current.focus();
        setuserInput("")
        setSelectedWord("")
        userInputRef.current.readOnly = false

    }, []);

    const closeAlert = useCallback(() => {
        if (alertRef.current) {
            alertRef.current.setShow(false);
        }
    }, [alertRef]);

    const closeVwAlert = useCallback(() => {
        if (alertWvRef.current) {
            alertWvRef.current.setShow(false);
        }
    }, [alertWvRef]);

    const help = useCallback(() => {
        setShowLoader(false);
        if (alertRef.current) {
            alertRef.current.show({
                type: alertType.message,
                content: contentHelp,
            });
        }
    }, [getLabel])

    useEffect(() => {
        setShowLoader(false);
        if (alertWvRef.current && selectedWord) {
            alertWvRef.current.show({
                type: alertType.message,
                content: <WordVocalization selectedWord={selectedWord} setSelectedWord={setSelectedWord} onWordChange={handleWordChange} closeVwAlert={closeVwAlert} />,
            });
        }
    }, [alertWvRef, selectedWord, setSelectedWord])

    const removeVocalization = useCallback(() => {
        setTextRemove(true)
    }, [dataNakdanRestoreKtivMale])

    const checkWord = useCallback((oneWord) => {
        if (!/\s/.test(oneWord) && !/[a-zA-Z]/.test(oneWord)) {
            setSelectedWord(oneWord)
        }
    }, [])

    useEffect(() => {
        if (dataText && dataNakdanRestoreKtivMale?.OutputText && textRemove == true) {
            setdataText(dataNakdanRestoreKtivMale.OutputText)
            setTextRemove(false)
        }
    }, [dataText, textRemove, dataNakdanRestoreKtivMale, userInput, userInputRef]);

    useEffect(() => {
        userInputRef.current.value = dataText
    }, [dataText])


    useEffect(() => {
        if (dataNakdanText?.OutputText) {
            setdataText(dataNakdanText.OutputText);
            setTextRemove(false)
            setuserInput("")
            userInputRef.current.readOnly = true

        }
        else if (errorNakdanText) {
            console.log(errorNakdanText)
        }
        setTextRemove(false)
        userInputRef.current.readOnly = false
    }, [dataNakdanText, errorNakdanText]);


    const onVocalizationButtonClickHandle = useCallback(() => {

        const userInputValue = userInputRef.current.value;
        if (userInputValue != null) {
            const userInputValue = userInputRef.current.value
            setuserInput(userInputValue);
        }
    }, [userInput]);

    const handleWordClick = useCallback((event) => {
        const textarea = userInputRef.current;
        const textValue = textarea.value;
        const caretPosition = textarea.selectionStart;

        const wordDelimiters = [' ', '\n', '\t', '.', ',', ';', ':', '!', '?'];
        let start = caretPosition;
        while (start > 0 && !wordDelimiters.includes(textValue[start - 1])) {
            start--;
        }
        let end = caretPosition;
        while (end < textValue.length && !wordDelimiters.includes(textValue[end])) {
            end++;
        }
        const word = textValue.substring(start, end);
        textarea.setSelectionRange(start, end);
        console.log(caretPosition)

        checkWord(word);
        setWordPosition({ start, end });

    }, [setWordPosition, checkWord, userInputRef, wordPosition]);

    const handleWordChange = useCallback((newWord) => {
        const textarea = userInputRef.current;
        const textValue = textarea.value;
        const updatedText = textValue.substring(0, wordPosition.start) + newWord + textValue.substring(wordPosition.end);
        setWordPosition(null)
        setdataText(updatedText);
    }, [setdataText, setWordPosition, userInputRef, wordPosition]);


    const handleMobileTouchStart = useCallback((event) => {
        const touch = event.touches[0];
        const currentTime = Date.now();
        
        setTouchStartTime(currentTime);
        setTouchStartPos({ x: touch.clientX, y: touch.clientY });
        setIsTouchMoved(false);
    }, []);
    

    const handleMobileTouchMove = useCallback((event) => {
        setIsTouchMoved(true);
    }, []);

    const handleMobileTouchEnd = useCallback((event) => {
        const currentTime = Date.now();
        const touchDuration = currentTime - touchStartTime;
        const timeSinceLastTap = currentTime - lastTapTime;
        
        // If the user was scrolling, don't handle the tap
        if (isTouchMoved) {
            return;
        }
    
        const touch = event.changedTouches[0];
        const clickEvent = {
            clientX: touch.clientX,
            clientY: touch.clientY,
            target: event.target
        };
    
        // Check for double tap
        if (timeSinceLastTap < DOUBLE_TAP_THRESHOLD) {
            handleWordClick(clickEvent);
            setLastTapTime(0); // Reset last tap time to prevent triple-tap
        }
        // Check for long press
        else if (touchDuration >= LONG_PRESS_DURATION) {
            handleWordClick(clickEvent);
        }
        // Update last tap time for potential double tap
        else {
            setLastTapTime(currentTime);
        }
    }, [handleWordClick, touchStartTime, isTouchMoved, lastTapTime]);

    const nav = useMemo(() => {
        return <ul className={styles.nav}>
            <button onClick={removeVocalization}><span>{getLabel("nakdan_Remove")}</span>
                <Icon icon="remove_text" className={styles.one_svg} width="25" height="25" /></button>
            <button onClick={increaseFontSize}><span>{getLabel("nakdan_increase")}</span>
                <div className={styles.tow_svg}>
                    <Icon icon="letter" width="13" height="15" />
                    <Icon icon="plus" width="13" height="11" />
                </div></button>

            <button onClick={decreaseFontSize}><span>{getLabel("nakdan_decrease")}</span>
                <div className={styles.tow_svg}>
                    <Icon icon="letter" width="13" height="15" />
                    <Icon icon="minus" width="13" height="13" />
                </div></button>

            <button onClick={clearText}><span>{getLabel("nakdan_clear_sceen")}</span>
                <Icon icon="clear" className={styles.one_svg} width="25" height="25" /></button>

            <button onClick={help}><span>{getLabel("nakdan_help")}</span>
                <Icon icon="help" className={styles.one_svg} width="25" height="25" /></button>
        </ul>;
    }, [getLabel, increaseFontSize, decreaseFontSize, clearText]);

    const contentHelp = useMemo(() => {
        return <div className={styles.help_pop_up}>
            <div className={styles.block_explain}>
                <span className={styles.header}>{getLabel("nakdan_header1")}</span>
                <ul>
                    <li><span>{getLabel("nakdan_Vocalization")}</span>{getLabel("nakdan_Vocalizatio_content")}</li>
                    <li><span>{getLabel("nakdan_Remove_Vocalization")}</span>{getLabel("nakdan_Remove_Vocalization_content")}</li>
                    <li><span>{getLabel("nakdan_increase")}</span> {getLabel("nakdan_Increase_Text_Size_content")}</li>
                    <li><span>{getLabel("nakdan_decrease")}</span>{getLabel("nakdan_Decrease_Text_Size_content")}</li>
                    <li><span>{getLabel("nakdan_clear_screen")}</span>{getLabel("nakdan_clear_screen_content")}</li>
                </ul>
            </div>
            <div className={styles.block_explain}>
                <span className={styles.header}>{getLabel("nakdan_header2")}</span>
                {!isMobileView &&
                    <div>{getLabel("nakdan_mid_text")}</div>
                }
                {isMobileView &&
                    <div>{getLabel("nakdan_mid_text_phone")}</div>}
                <ul>
                    {isMobileView &&
                        <>
                            <li><span>{getLabel("nakden_choose_word")}</span>{getLabel("nakden_choose_word_contnt_phone")}</li>
                            <li><span>{getLabel("nakdan_Manual_Vocalization")}</span>{getLabel("nakdan_Manual_Vocalization_content_phone")}</li>
                        </>}
                    {!isMobileView && <>
                        <li><span>{getLabel("nakden_choose_word")}</span>{getLabel("nakden_choose_word_contnt")}</li>
                        <li><span>{getLabel("nakdan_Manual_Vocalization")}</span>{getLabel("nakdan_Manual_Vocalization_content")}</li>
                        <li><span>{getLabel("nakdan_cancel")}</span> {getLabel("nakdan_close_content")}</li></>}
                </ul>
            </div>
            <Button
                type={buttonType.premium}
                className={styles.close_button}
                onClick={closeAlert}
            >
                {getLabel("nakdan_help_alert_button")}
            </Button>
        </div>
    }, [closeAlert, getLabel, isMobileView])

    return (
        <>
            <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{getLabel("page_nakdan_title")}</OuterLayer>
            {!isMobileView &&
                <>
                    <OuterLayer container={appLayoutSubTitleContainer} dangerouslySetInnerHTML={{ __html: getLabel("page_nakdan_sub_title") }} />
                    <div className={styles.container}>
                        <div className={styles.content}>
                            {nav}
                            <div className={styles.text_box}>
                                <textarea
                                    id="Textarea"
                                    className={styles.user_text}
                                    ref={userInputRef}
                                    onDoubleClick={handleWordClick}
                                    style={{ fontSize: `${fontSize}rem` }}
                                    disabled={!!selectedWord}
                                />
                            </div>
                            {!dataText && (
                                <Button className={styles.dark_botton_submit} onClick={onVocalizationButtonClickHandle}>
                                    {getLabel('nakdan_submit')}
                                </Button>
                            )}
                            {dataText && <>
                                {!selectedWord && <><div className={styles.instructions}><span>{getLabel("nakdan_instructions")}</span></div>
                                    <Button className={styles.dark_botton_submit} onClick={onVocalizationButtonClickHandle}>
                                        {getLabel('nakdan_submit')}
                                    </Button></>}
                                {selectedWord && <WordVocalization selectedWord={selectedWord} setSelectedWord={setSelectedWord} onWordChange={handleWordChange} />}
                            </>}
                        </div>
                    </div>
                </>
            }
            {isMobileView &&
                <div className={styles.container} ref={containerRef}>
                    <div className={styles.content}>
                        {dataText && nav}
                        <div className={styles.text_box}>
                            <textarea
                                id="Textarea"
                                className={styles.user_text}
                                ref={userInputRef}
                                onTouchStart={handleMobileTouchStart}
                                onTouchMove={handleMobileTouchMove}
                                onTouchEnd={handleMobileTouchEnd}
                                style={{ fontSize: `${fontSize}rem` }}
                                disabled={!!selectedWord}
                            />

                        </div>
                        {dataText && <>
                            {!selectedWord && < div className={styles.instructions}><span>{getLabel("nakdan_instructions_phone")}</span></div>}
                            <Alert ref={alertWvRef} lightMask={true} showClose={false} />
                            {showLoader && <Loader />}
                        </>
                        }
                        <Button className={styles.dark_botton_submit} onClick={onVocalizationButtonClickHandle}>
                            {getLabel('nakdan_submit')}
                        </Button>
                    </div>

                </div >
            }
            <Alert ref={alertRef} />
            {showLoader && <Loader />}

        </>
    );
};

export default Nakdan;