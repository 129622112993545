import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import OuterLayer from '../../containers/OuterLayer';
import { useLabels } from '../../hooks';
import { PageContext, OutersContainerContext, DeviceContext, UserContext } from '../../contexts';
import styles from './Transcription.module.scss';
import Icon from '../../components/Icon';
import { debounce } from 'lodash';
import AudioService from './AudioService';
import WebSocketService from './WebSocketService';
import CircularProgress from '@mui/material/CircularProgress';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';

const pageKey = "transcription";

const Transcription = () => {

    const [finalTranscriptions, setFinalTranscriptions] = useState([]);
    const [ongoingTranscription, setOngoingTranscription] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [error, setError] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [isRecordingReady, setIsRecordingReady] = useState(false);
    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const [translatingRows, setTranslatingRows] = useState(new Set());
    const [direction, setDirection] = useState('right');
    const divRef = useRef(null);
    const transcriptionRef = useRef(null);

    const audioServiceRef = useRef(null);
    const wsServiceRef = useRef(null);

    let lastClass = styles.wrafBoxLeft;

    useEffect(() => {
        const updateTranscription = (data) => {
            console.log('Updating transcription:', data);
            setOngoingTranscription(data.text);
        };

        wsServiceRef.current = new WebSocketService();
        wsServiceRef.current.init({
            onTranscription: (data) => {
                setIsConnected(true); // Connection is working if we get data
                console.log('Received transcription:', data);
                if (data.is_final) {
                    // Add to transcriptions immediately with pending translation
                    setFinalTranscriptions(prev => [...prev, {
                        ...data,
                        translation: 'מתרגם...',
                        translating: true
                    }]);
                    setTranslatingRows(prev => new Set(prev).add(data.text));
                    setOngoingTranscription(''); // Clear intermediate text
                } else {
                    updateTranscription(data);
                }
            },
            onTranslation: (data) => {
                setIsConnected(true);
                console.log('Received translation:', data);
                setTranslatingRows(prev => {
                    const updated = new Set(prev);
                    updated.delete(data.text);
                    return updated;
                });
                setFinalTranscriptions(prev =>
                    prev.map(row =>
                        row.text === data.text ? { ...data, translating: false } : row
                    )
                );
            },
            onError: (data) => {
                // Only show errors after initial connection is established
                if (isConnected) {
                    console.error('Received error:', data);
                    setError(data.message);
                }
            },
            onConnectionChange: (connected) => {
                setIsSocketConnected(connected);
                if (connected) {
                    audioServiceRef.current?.resumeProcessing();
                } else {
                    audioServiceRef.current?.pauseProcessing();
                }
            }
        });

        audioServiceRef.current = new AudioService((audioData) => {
            wsServiceRef.current.sendAudioData(audioData);
        });

        return () => {
            wsServiceRef.current.disconnect();
        };
    }, []);

    useEffect(() => {
        if (isRecording)
            startRecording()
        else
            stopRecording()
    }, [isRecording])

    const startRecording = async () => {
        setIsRecordingReady(false);
        try {
            await audioServiceRef.current.startRecording(() => {
                setIsRecordingReady(true); // Recording is ready
            });
            setIsRecording(true);
        } catch (err) {
            setError('Failed to start recording: ' + err.message);
        }
    };

    const stopRecording = () => {
        audioServiceRef.current.stopRecording();
        setIsRecording(false);
    };

    const { getLabel } = useLabels();
    const {
        appLayoutTitleContainer,
        appLayoutSubTitleContainer
    } = useContext(OutersContainerContext);

    const handleToggleRecording = () => {
        setIsRecording(!isRecording);
        console.log(isRecording ? 'ההקלטה נעצרה' : 'ההקלטה התחילה');
    };

    const getCurrentTime = () => {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };



    const [textContent, setTextContent] = useState('');

    useEffect(() => {
        if (divRef.current) {
            setTextContent(divRef.current.innerText)
        }
    }, [])


    const downloadTranscript = () => {
        if (divRef.current) {
            const element = divRef.current;
            const clonedElement = element.cloneNode(true);
    
            clonedElement.style.maxHeight = 'none';
            clonedElement.style.overflowY = 'visible';
            clonedElement.style.width = 'calc(210mm - 50mm)';
            clonedElement.style.height = 'auto';
            clonedElement.style.margin = '5mm';
            clonedElement.style.padding = '0';
            clonedElement.style.boxSizing = 'border-box';
            clonedElement.style.fontSize = '12pt';
            clonedElement.style.fontFamily = 'Arial, sans-serif';
            clonedElement.style.wordWrap = 'break-word';
            clonedElement.style.direction = 'rtl';
            clonedElement.style.whiteSpace = 'pre-wrap';
    
            clonedElement.style.pageBreakInside = 'avoid';
    
            Array.from(clonedElement.querySelectorAll('p')).forEach(p => {
                p.style.pageBreakAfter = 'avoid';
                p.style.minHeight = '1.5em';
            });
    
            document.body.appendChild(clonedElement);
    
            const opt = {
                margin: [15, 15, 15, 15],
                filename: 'transcript.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 3, logging: true },
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait',
                    pagesplit: true
                }
            };
    
            html2pdf().from(clonedElement).set(opt).save().then(() => {
                document.body.removeChild(clonedElement);
            }).catch(error => {
                document.body.removeChild(clonedElement);
                console.error("pdf error", error);
                alert("אירעה שגיאה בהורדת התמלול. נסה שוב מאוחר יותר.");
            });
    
        } else {
            console.error("Div element not found!");
            alert("אירעה שגיאה בהורדת התמלול. נסה שוב מאוחר יותר.");
        }
    };

    useEffect(() => {
        if (transcriptionRef.current) {
            const currentTranscriptionDiv = transcriptionRef.current.querySelector(`.${styles.currentTranscription}`);
            if (currentTranscriptionDiv) {
                currentTranscriptionDiv.scrollTop = currentTranscriptionDiv.scrollHeight;
            }
        }
    }, [ongoingTranscription]);

    // Scroll final transcriptions
    useEffect(() => {
        if (divRef.current) {
            const emptyDiv = divRef.current.querySelector('#empty');
            if (emptyDiv) {
                emptyDiv.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        }
    }, [finalTranscriptions]);

    return <>
        <OuterLayer container={appLayoutTitleContainer} TagWrapper='span'>{getLabel("page_transcription_title")}</OuterLayer>
        <OuterLayer container={appLayoutSubTitleContainer} dangerouslySetInnerHTML={{ __html: getLabel("page_transcription_sub_title") }} />
        <div className={styles.containerTranscription} ref={transcriptionRef}>
            <div sx={{ mb: 2 }}>
                <div className={styles.currentTranscription}>{ongoingTranscription}</div>
            </div>
            <div className={styles.translationBox} ref={divRef}>
                {finalTranscriptions.map((row, index) => {
                    const isDifferentSpeaker = index === 0 || row.speaker !== finalTranscriptions[index - 1].speaker;
                    if (isDifferentSpeaker) {
                        lastClass = lastClass === styles.wrafBoxLeft ? styles.wrafBoxRight : styles.wrafBoxLeft;
                    }

                    return (
                        <div key={`final-${index}`} className={lastClass}>
                            <div className={styles.box}>
                                <div className={styles.speakBox}>{row.text}</div>
                                <div className={styles.textUnderBox}>
                                    {row.speaker} | {getCurrentTime()}
                                </div>
                            </div>
                            <div className={styles.box}>
                                {row.translating ? (
                                    <div className={styles.translating}>
                                        <CircularProgress size={16} sx={{ mr: 1 }} />
                                        {row.translation}
                                    </div>
                                ) : (
                                    row.translation
                                )}
                            </div>
                        </div>
                    );
                })}
                <div className={styles.empty} id="empty" style={{ float: 'left', clear: 'both' }}></div>
            </div>

            <div className={`${styles.microphone} ${isRecording ? styles.active : ''}`}
                onClick={handleToggleRecording}
            >
                <div className={`${styles.circle} ${styles.firstCircle}`}></div>
                <div className={`${styles.circle} ${styles.secondCircle}`}></div>
                <Icon icon="speaker" width="37" height="37" />
            </div>
            <div className={styles.download}>
                <p> לייצוא השיחה כטקסט</p>
                <button className={styles.buttonDown} onClick={downloadTranscript}>לחץ כאן</button>
            </div>
        </div>
    </>
};

export default Transcription;


