const isOrgSide = (process.env.REACT_APP_ORG_ENABLE === "true" || process.env.REACT_APP_ORG_ENABLE === window.location.host);
const isProxy = () => {return !!window.fetchProxy};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    REACT_APP_NODE_ENV: process.env.REACT_APP_NODE_ENV,
    REACT_APP_BASS_RAVMILIM_API_URL: () => {return isProxy() ? window.fetchProxy.apiUrl : process.env.REACT_APP_BASS_RAVMILIM_API_URL},
    REACT_APP_USER_POOL_ID: isOrgSide ? process.env.REACT_APP_USER_POOL_EXTERNAL_ID : process.env.REACT_APP_USER_POOL_ID,
    REACT_APP_USER_POOL_CLIENT_ID: isOrgSide ? process.env.REACT_APP_USER_POOL_EXTERNAL_CLIENT_ID : process.env.REACT_APP_USER_POOL_CLIENT_ID,
    REACT_APP_ORG_ENABLE: () => {return isOrgSide || isProxy()},
    REACT_APP_TRANSCRIPTION_API_URL: process.env.REACT_APP_TRANSCRIPTION_API_URL,
    REACT_APP_BASE_NAKDAN_API_URL: process.env.REACT_APP_BASE_NAKDAN_API_URL,
    REACT_APP_BILLING_API_URL: process.env.REACT_APP_BILLING_API_URL,
};