import { createContext, useState, useEffect, useCallback, useRef } from 'react';
import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails,
    CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import { userApi } from '../api'
import { env } from '../config';
import { throttle, getDeviceUUID, getSessionUUID } from '../utils/helpers';


const userPool = new CognitoUserPool({
    UserPoolId: env.REACT_APP_USER_POOL_ID,
    ClientId: env.REACT_APP_USER_POOL_CLIENT_ID,
});

const getCognitoUser = () => {
    return userPool.getCurrentUser();
};

const getCognitoSession = () => {
    return new Promise((resolve, reject) => {
        const cognitoUser = getCognitoUser();
        if (cognitoUser) {
            cognitoUser.getSession(function (error, session) {
                if (error || !session.isValid()) {
                    reject(error);
                    return;
                }

                resolve({ session, cognitoUser });
            });
        }
        else {
            reject();
        }
    });
};

const getCognitoSessionJwtToken = () => {
    let jwtToken;
    const cognitoUser = getCognitoUser();
    if (cognitoUser && cognitoUser.storage) {
        jwtToken = cognitoUser.storage[`${cognitoUser.keyPrefix}.${cognitoUser.username}.idToken`]; // accessToken, idToken
    }
    return jwtToken;
};

const getCognitoSessionRefreshToken = () => {
    let refreshToken;
    const cognitoUser = getCognitoUser();
    if (cognitoUser && cognitoUser.storage) {
        refreshToken = new CognitoRefreshToken({
            RefreshToken: cognitoUser.storage[`${cognitoUser.keyPrefix}.${cognitoUser.username}.refreshToken`]
        });
    }
    return refreshToken;
};

const setSessionJwtTokenExpiredTime = (exp) => {
    window.localStorage.setItem('CognitoSessionJwtTokenExpiredTime', exp);
}
const getSessionJwtTokenExpiredTime = () => {
    let exp = localStorage.getItem('CognitoSessionJwtTokenExpiredTime');
    return Number(exp ? exp : 0);
}
const setSessionRefreshTokenExpiredTime = (exp) => {
    window.localStorage.setItem('CognitoSessionRefreshTokenExpiredTime', exp);
}
const getSessionRefreshTokenExpiredTime = () => {
    let exp = localStorage.getItem('CognitoSessionRefreshTokenExpiredTime');
    return Number(exp ? exp : 0);
}

export const UserType = {
    guest: 'GUEST',
    privet: 'PRIVET',
    edu: 'EDU',
    org: 'ORG',
    external: 'EXTERNAL'
}

const apiGuestUserData = {
    isActive: false,
    userType: UserType.guest,
    listProducts: []
};

export const UserContext = createContext();

export const UserProvider = (props) => {
    const apiUserData = useRef(apiGuestUserData);
    const [jwtToken, setJwtToken] = useState();
    const [userType, setUserType] = useState('');
    const [hasPermissions, setHasPermissions] = useState(false);
    const [userId, setUserId] = useState(null);
    const [userData, setUserData] = useState({});
    const [codeDeliveryDetails, setCodeDeliveryDetails] = useState();
    const [orgData, _setOrgData] = useState();
    const [listProducts, setListProducts] = useState([]);

    /*
    useEffect(() => {
       console.log(jwtToken);
    }, [jwtToken]);
    */

    const updateSession = useCallback(() => {
        if (apiUserData.current.userType === UserType.guest) {
            setJwtToken('');
            setUserType(apiUserData.current.userType);
            setHasPermissions(apiUserData.current.isActive);
            setUserId(null);
            setUserData({});
            setSessionJwtTokenExpiredTime(0);
            return;
        }

        if (apiUserData.current.userType === UserType.org) {
            setJwtToken('');
            setUserType(apiUserData.current.userType);
            setHasPermissions(apiUserData.current.isActive);
            setUserId(apiUserData.current.userId);
            setUserData({});
            setSessionJwtTokenExpiredTime(0);
            return;
        }

        getCognitoSession().then(({ session, cognitoUser }) => {
            setJwtToken(getCognitoSessionJwtToken());
            setUserType(apiUserData.current.userType);
            setHasPermissions(apiUserData.current.isActive);
            setUserId(apiUserData.current.userId);
            setSessionJwtTokenExpiredTime(session.idToken.payload.exp * 1000);


            if (cognitoUser) {
                cognitoUser.getUserData(function (error, userData) {
                    if (userData && userData.UserAttributes) {
                        const newUserData = userData.UserAttributes.reduce((userAttributes, attribute) => {
                            userAttributes[attribute.Name] = attribute.Value;
                            return userAttributes;
                        }, {});
                        setUserData(newUserData);
                    }
                });
            }
        }).catch(() => {
            apiUserData.current = apiGuestUserData;
            setJwtToken('');
            setUserType(UserType.guest);
            setHasPermissions(false);
            setUserId(null);
            setUserData({});
            setSessionJwtTokenExpiredTime(0);
            setListProducts([]);
        });
    }, []);

    const getApiUserData = useCallback(({ isSignOut = false, retry = false }) => {
        if (isSignOut) {
            apiUserData.current = apiGuestUserData;
            setListProducts([]);
            updateSession();
        }

        userApi.GetUserData(getCognitoSessionJwtToken()).then((_apiUserData) => {
            apiUserData.current = _apiUserData;
            setListProducts(apiUserData.current.listProducts);

            if (apiUserData.current.userType === UserType.org) {
                updateSession();
            }
            else if (apiUserData.current.userType === UserType.guest) {
                const cognitoUser = getCognitoUser();
                if (cognitoUser) {
                    cognitoUser.signOut();
                }
                if (retry) {
                    getApiUserData({ isSignOut });
                }
                else {
                    updateSession();
                }
            }
            else {
                updateSession();
            }
        }).catch((error) => {
            apiUserData.current = apiGuestUserData;
            setListProducts([]);
            updateSession();
        });
    }, [updateSession]);

    const initApiUserData = useCallback(() => {
        getApiUserData({ retry: !!getCognitoSessionJwtToken() });
    }, [getApiUserData]);

    useEffect(() => {
        const cognitoUser = getCognitoUser();
        if (cognitoUser && getSessionRefreshTokenExpiredTime() > window.Date.now()) {

            cognitoUser.refreshSession(getCognitoSessionRefreshToken(), (error, newSession) => {
                initApiUserData();
            });
        }
        else {
            initApiUserData();
        }
    }, [initApiUserData]);

    const pingUserData = useCallback(() => {
        if (
            apiUserData.current &&
            (
                apiUserData.current.userType === UserType.org ||
                apiUserData.current.userType === UserType.privet ||
                apiUserData.current.userType === UserType.external
            )
        ) {
            initApiUserData();
        }
    }, [initApiUserData]);

    const signOut = useCallback(() => {
        const cognitoUser = getCognitoUser();
        if (cognitoUser) {
            cognitoUser.signOut();
        }
        setSessionRefreshTokenExpiredTime(0);
        getApiUserData({ isSignOut: true });
    }, [getApiUserData]);

    const signIn = useCallback((email, password) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
            ValidationData: {
                ip: apiUserData.current?.ip || '',
                device_uuid: getDeviceUUID(),
                session_uuid: getSessionUUID(true),
            }
        });

        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (newSession) {
                    setCodeDeliveryDetails(null);
                    getApiUserData({});
                    const date = new Date();
                    date.setDate(date.getDate() + 180)
                    date.setMinutes(date.getMinutes() - 10)
                    setSessionRefreshTokenExpiredTime(date.getTime())
                    resolve(newSession);
                },

                onFailure: function (error) {
                    getApiUserData({ isSignOut: true });
                    setSessionRefreshTokenExpiredTime(0);
                    if (error.message && error.message.indexOf('CognitoUserLimitLoginError') >= 0) {
                        reject({
                            code: 'CognitoUserLimitLoginError',
                            message: error.message
                        });
                    }
                    else if (error.message && error.message.indexOf('CognitoUserDailyLimitLoginError') >= 0) {
                        reject({
                            code: 'CognitoUserDailyLimitLoginError',
                            message: error.message
                        });
                    }

                    else {
                        reject(error);
                    }
                },

                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    delete userAttributes.email_verified;
                    delete userAttributes.email;
                    delete userAttributes.phone_number_verified;
                    delete userAttributes.phone_number;
                    delete userAttributes["custom:org_id"];

                    reject({
                        code: 'HandleNewPasswordRequired',
                        cognitoUser,
                        userAttributes
                    });
                },
            });
        });
    }, [getApiUserData]);

    const getJwtToken = useCallback(() => {
        return getCognitoSessionJwtToken();
    }, []);


    const completeNewPasswordChallenge = useCallback((cognitoUser, newPassword, sessionUserAttributes) => {
        cognitoUser.completeNewPasswordChallenge(newPassword, sessionUserAttributes);
    }, []);

    const refreshSessionNow = useCallback(() => {
        if (apiUserData.current.userType === UserType.privet || apiUserData.current.userType === UserType.external || apiUserData.current.userType === UserType.edu) {
            const cognitoUser = getCognitoUser();
            if (cognitoUser) {
                const refresh_token = getCognitoSessionRefreshToken();

                cognitoUser.refreshSession(refresh_token, (error, newSession) => {
                    if (error) {
                        signOut();
                    } else {
                        updateSession();
                    }
                });
            }
        }
    }, [signOut, updateSession]);

    const refreshSession = useCallback(throttle(() => {
        refreshSessionNow();
    }, 1000 * 60 * 10), [refreshSessionNow]);

    useEffect(() => {
        document.addEventListener("click", refreshSession);
        const interval = window.setInterval(() => {
            const cognitoUser = getCognitoUser();
            if (apiUserData.current && (apiUserData.current.userType === UserType.privet || apiUserData.current.userType === UserType.external || apiUserData.current.userType === UserType.edu)) {
                if (!cognitoUser || getSessionRefreshTokenExpiredTime() < window.Date.now()) {
                    signOut();
                }
            }
            else if (apiUserData.current && apiUserData.current.userType === UserType.guest) {
                if (cognitoUser) {
                    initApiUserData();
                }
            }
        }, 1000 * 5);

        return () => {
            document.removeEventListener("click", refreshSession);
            window.clearInterval(interval);
        };
    }, [refreshSession, signOut, initApiUserData]);

    const sendResetPasswordCode = useCallback((email) => {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                onSuccess: function (data) {
                    resolve(data.CodeDeliveryDetails);
                },
                onFailure: function (error) {
                    reject(error);
                },
            });
        });
    }, []);

    const confirmPassword = useCallback((email, verificationCode, newPassword) => {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess: function (data) {
                    resolve();
                },
                onFailure: function (error) {
                    reject(error);
                },
            });
        });
    }, []);

    const signUp = useCallback((signUpData = {}) => {
        const data = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            ...signUpData,
        };

        const attributeList = [
            { key: 'firstName', name: 'given_name' },
            { key: 'lastName', name: 'family_name' },
            { key: 'email', name: 'email' }
        ].map(attribute => new CognitoUserAttribute({ Name: attribute.name, Value: data[attribute.key] }));
        if (data.phone) {
            if (data.phone.substring(0, 1) !== '+') {
                data.phone = `+972${data.phone}`;
            }
            attributeList.push(new CognitoUserAttribute({ Name: 'phone_number', Value: data.phone }));
        }

        if (env.REACT_APP_ORG_ENABLE()) {
            attributeList.push(new CognitoUserAttribute({ Name: 'custom:org_id', Value: orgData.id }));
        }

        return new Promise((resolve, reject) => {
            userPool.signUp(data.email, data.password, attributeList, null, function (error, result) {
                if (error) {
                    if (error.code === 'NotAuthorizedException') {
                        reject({
                            code: 'SignUpNotAuthorizedException',
                            message: error.message
                        });
                    }
                    else if (error.message && error.message.indexOf('CognitoOrgEmailDomainError') >= 0) {
                        reject({
                            code: 'CognitoOrgEmailDomainError',
                            message: error.message
                        });
                    }
                    else {
                        reject(error);
                    }
                    return;
                }

                if (result.codeDeliveryDetails && !result.userConfirmed) {
                    setCodeDeliveryDetails({
                        username: data.email,
                        password: data.password,
                        ...result.codeDeliveryDetails
                    });
                }

                resolve();
            });
        });
    }, [orgData]);

    const confirmAccount = useCallback((code) => {
        const userData = {
            Username: codeDeliveryDetails ? codeDeliveryDetails.username : '',
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmRegistration(code, true, function (error, result) {
                if (error) {
                    reject(error);
                    return;
                }

                resolve();
            });
        });
    }, [codeDeliveryDetails]);

    const resendConfirmationAccountCode = useCallback((email, password) => {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.resendConfirmationCode(function (error, result) {
                if (error) {
                    reject(error);
                    return;
                }

                setCodeDeliveryDetails({
                    username: email,
                    password: password,
                    ...(result.codeDeliveryDetails || result.CodeDeliveryDetails)
                });
                resolve();
            });
        });
    }, []);

    const updateAttributes = useCallback((updateData = {}) => {
        const data = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            ...updateData,
        };

        if (data.phone && data.phone.substring(0, 1) !== '+') {
            data.phone = `+972${data.phone}`;
        }

        const attributeList = [
            { key: 'firstName', name: 'given_name' },
            { key: 'lastName', name: 'family_name' },
            { key: 'phone', name: 'phone_number' }
        ].map(attribute => new CognitoUserAttribute({ Name: attribute.name, Value: data[attribute.key] || null }));

        if (!env.REACT_APP_ORG_ENABLE()) {
            attributeList.push(new CognitoUserAttribute({ Name: 'email', Value: data['email'] || null }));
        }

        return new Promise(async (resolve, reject) => {
            try {
                const result = await userApi.UpdateUserData(data, getCognitoSessionJwtToken());

                if (!result.success) {
                    reject(result);
                    return;
                }

                const { session, cognitoUser } = await getCognitoSession();
                cognitoUser.updateAttributes(attributeList, function (error, result) {
                    if (error) {
                        reject(error);
                        return;
                    }

                    updateSession();
                    resolve(result);
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }, [updateSession]);

    const changePassword = useCallback((oldPassword, newPassword) => {
        return new Promise((resolve, reject) => {
            getCognitoSession().then(({ session, cognitoUser }) => {
                cognitoUser.changePassword(oldPassword, newPassword, function (error, result) {
                    if (error) {
                        if (error.code === 'NotAuthorizedException') {
                            reject({
                                code: 'ChangePasswordNotAuthorizedException',
                                message: error.message
                            });
                        }
                        else {
                            reject(error);
                        }
                        return;
                    }

                    resolve();
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }, []);

    const setOrgData = useCallback((orgCode) => {
        return new Promise((resolve, reject) => {
            if (!orgCode) {
                _setOrgData(null);
                resolve(null);
                return;
            }

            userApi.GetOrgData(orgCode).then((orgData) => {
                orgData.isActive = new Date(orgData.expiryDate) > new Date();
                _setOrgData(orgData);
                resolve(orgData);
            }).catch((error) => {
                _setOrgData(null);
                resolve(null);
            });
        });
    }, []);

    return <UserContext.Provider value={{
        userType,
        hasPermissions,
        userData,
        listProducts,
        codeDeliveryDetails,
        setCodeDeliveryDetails,
        pingUserData,
        getJwtToken,
        signIn,
        signOut,
        signUp,
        completeNewPasswordChallenge,
        sendResetPasswordCode,
        confirmPassword,
        confirmAccount,
        resendConfirmationAccountCode,
        updateAttributes,
        changePassword,
        orgData,
        setOrgData,
    }}>
        {props.children}
    </UserContext.Provider>;
};